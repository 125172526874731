.photoCarousel {
  font-style: italic;
  font-size: 14px;
  margin: 10px 0;
  justify-self: end;
  text-align: right;
}

.leftArrow {
  height: 20px;
  width: 20px;
}

.rightArrow {
  height: 20px;
  width: 20px;
}

.image {
  max-width: 600px;
  width: 100%;
}

@media (min-width: 1024px) {
  .image {
    max-width: 100%;
    width: auto;
    max-height: 700px;
  }
}

@media (max-width: 768px) {
  .slider {
    width: calc(100vw - 40px) !important;
    margin: 0 20px !important;
  }
}
