.imageContainer {
  display: block;
  position: relative;
  max-width: 100%;
  width: auto;
}

.subtitle {
  margin: 30px 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.date {
  font-size: 18px;
}

.tag {
  margin: 10px 20px;
  font-size: 16px;
  color: #707070;
  a {
    color: inherit;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

@media (min-width: 1024px) {
  .imageContainer {
    display: inline-block;
    margin-bottom: 40px;
    max-width: 100%;
    width: auto;
  }

  .imageContainer > div {
    max-width: 100% !important;
  }

  .subtitle {
    font-size: 20px;
  }

  .date {
    font-size: 20px;
  }

  .tag {
    font-size: 18px;
  }
}

.footerImage {
  display: relative;
  max-width: 600px;
  width: 100%;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .tag {
    margin: 10px 0;
  }
}

@media (min-width: 1024px) {
  .footerImage {
    max-width: 100%;
    width: auto;
    max-height: 700px;
  }
}
