.imageContainer {
  display: block;
  position: relative;
  max-width: 100%;
  width: auto;
}

.subtitle {
  margin: 30px 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.date {
  font-size: 18px;
}

.tag {
  margin: 10px 20px;
  font-size: 16px;
}

.headerTag {
  margin: 24px 20px;
  font-size: 16px;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.email {
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}

.headerImage {
  display: relative;
  max-width: 600px;
  width: calc(100% - 40px);
  padding: 20px 20px;
}

@media (min-width: 768px) {
  .tag {
    margin: 10px 0;
  }

  .headerImage {
    width: 100%;
    padding: 20px 0;
  }

  .headerTag {
    margin: 24px 0px;
  }
}

@media (min-width: 1024px) {
  .imageContainer {
    display: inline-block;
    margin-bottom: 40px;
    max-width: 100%;
    width: auto;
  }

  .imageContainer > div {
    max-width: 100% !important;
  }

  .subtitle {
    font-size: 20px;
  }

  .date {
    font-size: 20px;
  }

  .tag {
    font-size: 18px;
  }

  .headerTag {
    font-size: 18px;
  }
}
