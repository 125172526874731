.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "EB Garamond", serif;
  color: #707070;
  padding: 0 20px;
  max-width: 1530px;
  margin: auto;
  max-width: 100%;
  flex-grow: 1;
}

.header {
  font-size: 30px;
  color: #fba700;
  margin: 20px 0;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto auto 0 auto;
  padding: 20px 0;
  font-size: 12px;
}

.intro {
  margin: 20px 0 40px 0;
  font-size: 16px;
}

.email {
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  color: #707070;
}

.headline {
  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
  text-decoration: underline;
}

.pageSelection {
  width: 100vw;
  padding: 20px 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.pageSelectionDesktop {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}

.page {
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;
  font-style: italic;
  font-size: 18px;
  padding: 0 10px;
}

.selected {
  text-decoration: underline;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  transition: box-shadow 0.3s ease;
}

.sticky-with-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1024px) {
  .App {
    padding: 0 60px;
    align-items: start;
    text-align: start;
  }

  .headerAndIntro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    width: 400px;
  }

  .header {
    margin-top: 40px;
    font-size: 38px;
  }

  .intro {
    font-size: 18px;
  }

  .contentContainer {
    margin-left: 460px;
    width: calc(100% - 460px);
  }

  .pageSelectionDesktop {
    margin-top: 40px;
  }
}
