.imageContainer {
  display: block;
  position: relative;
  max-width: 100%;
  width: auto;
}

.subtitle {
  margin: 30px 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.tag {
  margin: 10px 20px;
  font-size: 16px;
}

.photoCarousel {
  font-style: italic;
  font-size: 14px;
  margin: 10px 0;
}

@media (min-width: 768px) {
  .tag {
    margin: 10px 0;
  }
}

@media (min-width: 1024px) {
  .imageContainer {
    display: inline-block;
    margin-bottom: 40px;
    max-width: 100%;
    width: auto;
  }

  .imageContainer > div {
    max-width: 100% !important;
  }

  .subtitle {
    font-size: 20px;
  }

  .tag {
    font-size: 18px;
  }
}
